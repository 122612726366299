<template>
  <div>
    <luis-quote class="mb-10" />
    <div class="flex items-center justify-center">
      <div>
        <svg
          height="300"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 404.7 354"
          enable-background="new 0 0 404.7 354"
          id="logo"
        >

          <!-- HI -->
          <path
            fill="none"
            stroke="#FFFFFF"
            stroke-width="2"
            d="M 287.2 163.2
    Q 271.75 81.75 182 87.75 130.6 97.05 107.05 144.3 91.55 175.3 102.8 209.45 123.25 271.7 192.75 279.05 225.5318359375 283.518359375 255.9 261.2 299.2931640625 226.9939453125 287.2 163.2
    M 182.25 92.7
    Q 263 86.65 282.65 158 293.2 196.4 273.5 228.75 230.25 299.85 155.7 266.4 97.55 240.25 105.8 177.55 114.75 109.45 182.25 92.7 Z"
          />


          <path
            fill="none"
            stroke="#FFFFFF"
            stroke-width="2"
            d="M 83.45 177.25
    Q 88.2 150.9 83.85 134.5 61.1 210.65 98.05 267.65 69.05 265.25 40.05 260.5 75.2 269.65 113.3 271.7 128.407421875 272.52265625 143.45 272.8
    L 138.65 269.8
    Q 118.3763671875 269.30859375 98.15 267.65 74.4 227.9 83.45 177.25
    M 78.9 122.95
    Q 78.9 123.2 78.75 123.25 72.7 127.75 82.4 130 80.95 126.15 78.9 122.95
    M 45.8 77.75
    L 43.6 77.9
    Q 6 139.75 11.3 220.25 14.2 264.55 39.6 260.15
    L 37.65 258.25
    Q 10.8 189.9 29.25 121.65 53.4 145.2 78.9 120.2 78.45 119.45 78.25 119.5 54.25 127.9 30.4 117.65 36.1 97.7 45.8 77.75
    M 57.7 74.5
    Q 57.25 74.75 56.95 75.1 56.05 76.05 55.25 77 54.6 77.65 54.05 78.3 53.4 79.05 53.5 80.25 53.5 80.6 53.5 81 54.5 84.3 58 85.1 68.05 87.4 73.15 80 73.45 78.5 72.6 78.95 64.3 83.1 56.6 79.5 57.15 78.75 57.7 78.75 63.65 78.65 67.4 76.5 67.55 76.1 67.5 76.1 67.4 76.05 67.35 76.05 62.95 73.8 57.7 74.5
    M 92.1 64.8
    Q 92.2 66.05 92.5 67.15 92.7 67.85 92.95 68.35
    L 93.7 70.2
    Q 112.85 70.35 131.85 65.7 132.05 66 132.2 66.25 132.35 66.05 132.35 65.85 132.3 62.75 131.25 59.85
    L 130.65 58
    Q 127.2 54.35 122.8 53.05
    L 117.2 54.1
    Q 104.3 57.6 92 61.8 91.95 62.15 91.95 62.55 92 63.65 92.1 64.8
    M 114.3 60.25
    Q 113.9 60.2 113.85 60.5 113.4 63.15 113.9 65.65 112.15 65.7 110.5 65.85 110.5 65.8 110.5 65.75 110.45 62.8 109.4 60.25 109.3 60.3 109.3 60.35 107.9 63.95 110.05 65.9 108.05 66.05 106.05 66.3 105.3 64.15 104.85 61.9 106.05 61.1 107.45 60.3 107.75 60.3 108 60.3 108.5 69.35 108.2 60.3 112.9 60.05 117.65 59.8 117.4 62.55 117.6 65.4 116.1 65.45 114.6 65.6 115.3 62.95 114.3 60.25
    M 104.3 62.25
    Q 103.85 64.7 104.9 66.4 102.35 66.7 99.8 67.05 97.75 67.3 95.8 67.7 95.8 67.25 96.05 67.1 99.7 64.95 104.3 62.25
    M 118 65.4
    Q 119.2 62.75 118.35 59.75 120.4 59.65 122.55 59.45 122 62.1 122.35 64.75 122.6 64.7 122.75 64.55 124 62.1 123.25 59.4 125.9 59.25 128.55 59.05 128.5 58.8 128.35 58.75 118.6 58 109.1 59.35 113.15 57.05 117.9 54.25 125.05 55.85 130.9 59.65
    L 130.85 59.65
    Q 130.85 63.6 131.65 65.4 129.65 65.25 127.55 65.25 127.05 62.25 126.5 59.3 126.45 59.3 126.5 59.35 124.85 63.05 126.75 65.25 122.4 65.2 118 65.4
    M 116.9 60.05
    L 116.85 60.05
    Q 117.45 67.75 116.9 60.05
    M 258.9 80.55
    L 260.85 80.9
    Q 288.6 85.9 315.9 95.4 344.35 174.3 322.55 253.2 300.1 255.55 277.45 259.5 272.614453125 260.342578125 267.8 261.1
    L 261.25 266.85
    Q 288.8001953125 263.492578125 316.3 258.4
    L 320.1 257.7
    Q 320.7 257.55 321.3 257.45
    L 321.3 257.5 322 257.35 323.3 253.15
    Q 334.4 216.5 334.65 176.9 334.9 128.45 322.45 97.75
    L 320.2 92.55 320.2 92.5 313.95 89.7
    Q 289.05 79.15 261 78.25 262.95 46.15 240.9 22.4
    L 233 14.8
    Q 231 13.15 228.95 11.5
    L 228.05 11.75
    Q 229.95 13.5 231.7 15.3 230.35 15.85 228.95 16.5 227.25 17.15 224.55 16.75 201 13.25 182.1 17.75 199.9 7.4 224.5 7.5 224.3 7 224 6.95 218.55 6 213.05 5.5 194.25 7.9 176.35 17.9 173.5 19.5 170.9 21.25 159.2 27.9 153.15 38.2 153.5 38.05 153.9 37.95 149 44.95 146 53.4 143.7 59.75 142.4 66.95 119.45 71.85 94.45 73.95 89.9 74.3 85.4 74.6
    L 84.55 74.65
    Q 83.2 74.75 81.95 74.8 79.3 71.95 73.6 70.75
    L 71.1 71
    Q 75.65 72.25 78.7 75.15 81.95 78.15 83.5 82.95 67.55 97.3 48.15 90.9 49.5 82.55 50.25 77.4 50.45 75.8 50.65 74.5 49.6 74.7 48.8 75.95 48.25 76.8 47.75 77.6 38.1 93.7 53.05 95.25 51.25 92.9 48 92 48 91.8 48.05 91.6 70.1 101.8 83.65 85.95
    L 84.15 82.35
    Q 84.6 78.1 82.4 75.35 117.85 79 148.6 71.45 144.9 68.05 146.85 62.9 152.9 46.7 162 35.2 203.85 21.8 237.95 22.35 256.75 45.65 258.75 78.2 252.05 78.05 245.2 78.45 252.05 79.35 258.9 80.55 Z"
          />
          <path
            fill="none"
            stroke="#FFFFFF"
            stroke-width="2"
            d="
            M 169.45 265.4
    Q 169.68125 269.425 166.6 270.1 179.9810546875 274.45 195.15 274.45 203.9392578125 274.45 212.1 273 209.7580078125 269.8974609375 206.85 267.8 201.95 264.25 196.35 264.25 192.45 264.25 186.9 265.15 182 266 181.35 266 180.05 266 175.95 263.6 171.7 261.15 171.4 259.8 169.8 260.25 169.45 261.9 169.3 262.65 169.45 265.4
    M 255.75 230.6
    Q 254.8 231.15 254.35 232.5 253.95 233.55 251.75 234.65 250.45 235.3 249.65 235.8 249.35 236.2 249.15 236.55 248.45 237.75 248.35 238.5
    L 248.35 240 248.85 240
    Q 250.1 239.2 255.25 236.55 259.6 233.5 258.6 229.75 256.45 230.15 255.75 230.6
    M 212.1 159
    Q 212.6 158.5 212.6 158.25 212.6 157.75 211.9 157.5 211.5 157.3 211 157.3 210.8 157.4 210.65 157.55 210.05 158.2 210.1 159.25 210.35 159.25 210.6 159.4 210.9 159.5 211.2 159.5 211.55 159.5 212.1 159
    M 216.35 156.2
    Q 216.2 157.05 214.85 158.6 214.355859375 159.1404296875 214 159.6
    L 214 161.5 213.55 161.5
    Q 214.982421875 161.5330078125 215.85 161.75
    L 217.85 161.75
    Q 216.85 159.85 216.65 159.2 216.2 157.85 216.35 156.2
    M 198.65 192.6
    Q 195.1 196.35 195.1 197.6 195.1 197.7 195.5 198.75 195.85 199.75 195.85 200.1 195.85 201.15 194.6 202.75 194.6 203.9 196.35 206.4 197.55 208.1 198.6 209.25 200.2 211 201.55 213.6 202 214.5 204.1 214.5 206.8 214.5 208.2 213.4 209.6 212.25 210.6 212.25 211.3 212.25 213.6 213.5 215.85 214.7 215.85 215
    L 213.85 219.1
    Q 213.85 219.2 215.75 221.95 217.6 224.7 217.6 227.85 217.6 228.1 217.1 228.85 216.65 229.65 216.6 230 217.25 230.5 217.7 230.85 217.85 231.05 217.85 232 217.85 233.55 216.75 235.45 215.6 237.4 215.6 239.6 215.6 241.2 216.9 245.55 217.4 247.3 217.75 248.45 220.6 247.55 228.1 245.4 235.85 242.85 235.85 241 235.45 238.65 235.6 238.3 235.7 238.1 239.1 237.25
    L 239.1 236.25
    Q 238.5 236.3 238.4 236.15 238.35 236.1 238.35 235.35 238.35 233.85 239.7 233.25 240.3 233 242.6 232.7 246.85 232.1 246.85 228.75 246.85 228 246 227.05 245.1 226.1 245.1 224.35 245.2 222.25 245.15 221.1 245.05 219.1 244.35 218 250.2 209.75 253.85 202.5 253.15 202.5 251.55 202.4 249.95 202.25 249.7 202.25 249.2 202.25 248.45 202.65 247.75 203 247.35 203 246.05 203 243.35 199.6 241.2 196.9 240.35 195.25 240.05 194.65 239.25 192.1 238.7 190.4 238.1 190.25 235.55 189.55 235 189.5 233.85 189.5 232.2 189.5 231.6 189.5 229.6 189.9 227.55 190.25 226.95 190.25 226.2 190.25 223.4 189.4 220.75 188.55 220.6 188.5 220.6310546875 188.24140625 220.65 188 220.7603515625 187.07421875 220.8 186.4 219.5 185.8 218.9 185.1 218.35 184.5 215.85 184.5
    L 212.95 184.5
    Q 211.65 184.7 209.35 186 208.85 186.3 206.4 186.8 204.2 187.3 203.35 188 201.45 189.55 198.65 192.6
    M 171.35 143
    Q 170.75 144.8 167.1 147.5 163.637890625 150.096484375 161 150.45 160.5900390625 150.5 160.2 150.5
    L 159.6 150.5
    Q 159.6 149.9 159.1 148.6 158.6 147.35 158.6 146.6 158.6 145.3263671875 161 143.05 161.2380859375 142.834765625 161.5 142.6 164.35 140.05 164.05 137.65 163.85 137.35 163.25 136.05 162.75 135 161.45 135 161.28203125 135 161 135.05 160.27578125 135.2521484375 158.8 135.9 156.75 136.75 156.45 136.75 150.25 136.75 146.25 135.8 137.1 133.65 137.1 127.25 136.5 127.25 133.6 127.8 132.75 127.95 132.1 128.1 131 128.4 130.1 128.8 128.1 129.65 126.8 130.9 124.8 133.2 123.1 134.5 123.65 134.75 123.8 135.35 123.85 135.6 123.85 136.75 123.85 137.9 123.8 138.25 123.65 139.15 123.1 139.5
    L 123.1 142 125.35 142
    Q 125.85 141.5 126.35 141.5
    L 126.35 145.75
    Q 122.05 148 120.4 150.75 118.9 153.1 118.6 157.5 118.5 159.1 117.4 164.55 116.35 169.55 116.35 171.75 116.35 173.4 118.15 179.75 119.6 184.85 120 186.25 119.6 184.55 119.6 183.35 119.6 182.35 120.25 180 121.05 177.25 121.85 176.75 122.1 183.35 126.45 190.1 130.7 196.8 131.3 201.15 130.5 203.25 128.15 207 126.6 209.45 126.6 212.75 126.6 219.5 128.4 221.9 129.55 223.45 132.85 224.05 136.55 224.8 138.35 226.25 141.45 228.85 143.35 235.5 145.2 242.2 150.8 247.6 153.3 250 158.2 254.4 157.95 253.45 157.65 252.35 156.35 247.3 156.35 245.75 156.35 244.35 156.85 242.1 157.35 239.9 157.35 238.75 157.35 233.6 159 227.85 160.6 222.05 160.6 222 160.1 216.5 160.1 215.85 160.1 214.5 159.75 212.05 159.45 210.35 159.4 209.05 158.5 208.5 157.2 207.6 155 206.05 153.35 206.25 151.5 206.45 149.6 205.1 147.7 203.4 146.85 202.75 142.85 201.95 139.8 201.25 133.85 199.9 133.85 197.25 133.85 196.95 134.35 196.5 134.8 196.05 135.1 196
    L 135.1 194.75
    Q 133.6 194.5 133.1 194.25
    L 133.1 193.75
    Q 135.35 191.45 135.35 189.5 135.35 188.3 133.8 187.45 132.45 186.7 130.6 186.5 130.1 184.5 130.1 183.25 130.1 181.9 134.1 179.75 137.8 177.75 139.2 177.75 140.75 177.75 143.05 178.75 145.65 179.95 145.85 181.25 146.2 183.8 146.8 185.15 147.25 186.2 147.95 187 147.85 186.5 147.85 185.85 147.85 180.8 154.2 176.2 155.85 174.95 159.15 172.6 161.7 170.5 162.6 168.5 164.5759765625 163.8806640625 167.4 161.5 169.8779296875 159.4228515625 173 159.05 175.9 158.7 176.65 158.1 177.7193359375 157.2150390625 178 154.75 178.1 153.9130859375 178.1 152.85 178.1 152.2376953125 178 151.65 177.61015625 149.4724609375 175.7 148 173.3 146.15 173.35 143
    L 171.35 143
    M 166.85 123.75
    Q 164.7 124.25 162.25 125.2 161.5990234375 125.48203125 161 125.75 158.8376953125 126.6626953125 157.35 127.25 156.5 126.25 156.25 125.75 155.8 124.9 156.35 124 155.35 123.75 154.7 123.75 150.1 123.75 148.55 125.75 147.7 127.65 146.7 128.75 150.55 128.75 153.5 130.4 156.45 132 157.1 132 159.2869140625 132 161 131.55 162.8515625 131.038671875 164.15 130 166.75 127.85 166.85 123.75
    M 182.1 127
    L 179.6 127
    Q 178.97421875 127.62578125 178 127.7 177.8068359375 127.75 177.6 127.75 177.8 128.05 177.95 129.35 177.9775390625 129.551953125 178 129.75 178.1 130.596875 178.1 131.25
    L 177.6 139.25
    Q 177.6 141.8 177.75 142.25 177.81796875 142.4265625 178 142.75 178.4888671875 143.5923828125 179.8 145.45 180.35 144.6 180.6 143.6 180.85 142.65 180.85 141 182.5 141.4 184.1 141 184.1 137.7 183.45 135.5 182.6 132.7 180.85 132.25
    L 180.85 130.25 182.1 128.75 182.1 127
    M 174.1 121.5
    Q 173.3 121 172.7 121 171.85 121 171.3 121.6 171.2 121.75 171.1 121.9 171.95 123.2 172.5 123.9 172.6 124.1 172.6 125.35 172.6 126.5 172.1 128 172.85 126.45 174.2 124.9 175.1 123.85 175.1 123 175.1 122.1 174.1 121.5
    M 238.85 145.25
    Q 237.0146484375 145.826953125 235.5 146.35 228.35 148.995703125 228.35 151.1 228.35 151.9 230 152.55 231.6 153.25 231.6 154.6 231.6 155.25 231.25 155.75 230.85 156.2 230.85 156.75 230.85 157.2 231.35 157.5 231.75 157.75 232.2 157.75 232.273046875 157.75 235.5 155.8 236.68671875 155.11796875 238.3 154.15 244.45 150.5 245.45 150.5 245.85 150.5 245.95 150.65 246.05 150.75 246.1 150.75 246 150.95 244.9 152.6 244.1 153.75 244.1 154.25 244.1 154.65 244.6 155.4 245.1 156.2 245.1 156.25 245.1 158.4 239.6 160 237.3701171875 160.6333984375 235.5 160.95 233.6796875 161.25 232.2 161.25 231.85 161.25 230.85 160.9 229.8 160.5 229.7 160.5 229.5 160.5 222.65 163.2 215.55 165.95 212.85 166.25 214.35 167.75 214.35 168.25 214.35 168.85 213.3 170.5 212.15 172.25 212 172.75 210.95 172.25 209.95 172.25 208.25 172.25 203.6 173.75 203.6 176.05 203.5 177 203.3 179.15 202.6 180.25 203.25 180.6 206.3 181.2 209.15 181.75 209.6 181.75 211.35 181.75 212.8 180.25 213.15 179.85 215.15 177.05 218.15 172.85 222.35 174.25 222.35 175.1 222.85 176.3 223.35 177.45 223.35 177.6 223.35 177.95 223.1 179.05 222.85 180.2 222.85 180.5 222.85 181.35 223.35 182.4 223.9 183.4 224.6 184 224.05 184.75 223.35 184.75
    L 223.35 185 226.6 185
    Q 226.45 184.3 225.85 183.25 225.35 182.35 225.35 181.5 225.35 180.65 227.1 178.75 228 177.8 228.85 177 229.6 177 230.2 177.5 231.05 178.2 231.85 178.5 231.85 179 231.1 180
    L 231.1 181.25
    Q 233.25 180.6 234.85 182.75 235.2423828125 183.2435546875 235.5 183.7 236.2607421875 185.1556640625 235.85 186.5 237.9 184.65 241.1 185
    L 241.1 183.75
    Q 240.45 183.5 240.05 182.9 240 182.8 239.35 181.5 240.8 180.75 244.85 179.5 245.65 179.5 247.25 180.6 248.75 181.65 249.35 182.5 248.4 182.55 246.5 185.2 244.9 187.45 242.6 186.5 242.7 187.45 241.9 188.3 241 189.2 239.85 189 240.85 190 243.55 194.65 246.45 198.75 249.7 198.75 251.15 198.75 254.1 196.25 256.85 193.9 256.85 193.25 256.85 192.6 254.2 189.45 252.55 187.5 251.35 186.3 252.05 185.8 253.2 185 254.45 185 256.4 187.25 258.3 189.45 258.85 191.5
    L 260.1 191.5
    Q 260.5 191.3 264.7 190.25 265.55 190.25 266.55 191.65 267.5 193 268.85 192.75
    L 268.9 192.9
    Q 268.95 193 269.1 193 269.05 193.55 268.55 194 268.1 194.35 267.95 194.45 269.45 194.25 272.85 194.25
    L 272.85 195.5
    Q 272.8 195.65 272.1 196.6 271.6 197.3 271.6 197.6 271.6 201.6 272.75 203.75 273.85 205.85 273.85 207.75 273.85 208.5 273.25 209.55 272.6 210.6 272.35 211.1 273.35 210.25 276.6 207.8 280.3 204.9 280.85 204 280.85 203.95 282.25 199.45 282.7470703125 197.913671875 284.6 197.6 285.5 191.041015625 285.5 184.1 285.5 159.9712890625 274.5 140.4 271.740234375 141.7634765625 270.3 144.5 268.85 147.25 265.6 147.25 264.95 147.25 262.05 146.5 259.15 145.75 257.6 145.75 257.6 143.8 255.4 142.85 254.05 142.25 252.7 142.25 248.4 142.25 238.85 145.25
    M 209.75 146.55
    Q 209.8 145.25 210.1 145
    L 208.1 145
    Q 207.65 145.45 207 145.5 206.9 145.8 206.8 146.1 206.3 147.55 205.85 148
    L 205.85 148.5 208.6 148.5
    Q 209.75 147.8 209.75 146.55
    M 229.85 118.85
    Q 229.85 117.95 227.85 117 225.85 116 225.85 115 225.85 114.6 226.35 113.9 226.85 113.15 226.85 112.25 226.85 111.65 224.1 108.75 221.25 105.75 220.35 105.75 219.85 105.75 218.3 106.75 216.8 107.75 214.6 107.75 213.15 107.75 211.7 108.25 210.3 108.75 209.7 108.75 209.65 108.75 207.65 107.9 205.6 107 204.6 107 204.3 107 203.6 107.2 202.25 108.65 198.6 110.9 197.7908203125 111.40859375 197.15 112 195.803515625 113.3 195.35 115 195.1 115.8724609375 195.1 116.85
    L 195.6 123.85
    Q 195.6 128.65 193.1 132.35 190.6 136 190.6 137 190.6 138.1 191 141.15 191.35 144.05 191.6 145 191.3 139.75 199.2 136.3 200.7 135.6 205.6 133.9 209.15 132.6 210.35 131.75 210.551953125 131.6056640625 214 129.4 216.527734375 127.7783203125 220.8 125.05 229.85 119.25 229.85 118.85 Z"
          />

          <path
            fill="none"
            stroke="#FFFFFF"
            stroke-width="2"
            d="M 307.4 110.85
    Q 306.8 110.85 307.05 111.2 308.95 113.5 311.3 113.95 311.3 114.05 311.25 114.15 308.95 123.1 305.3 131.6 305.15 131.5 305.1 131.35 301.4 120.65 295.3 111.6 297.1 111.35 299.4 110.25 299.35 110.05 299.25 109.95 295.25 107.45 289.95 108.25
    L 289.6 108.35
    Q 290.95 111.55 294.1 111.65 299.75 125.35 305.4 139 306.3 138.8 306.65 138 312.3 124.9 312.45 114.05 313.95 114 315.65 113.25 315.6 113.05 315.5 113 311.6 111.55 307.4 110.85
    M 281.45 93.75
    Q 277.6 94.35 274.3 95.5 274.3 95.7 274.35 95.7 276.45 96.15 278 96.35 278.5 109.6 277.7 122.6 286.1 122.2 293.05 119.1 286.4 118.05 280.05 119.6 280.05 119.45 280.05 119.25 281.55 107.2 279.45 96.45 283.2 96.5 281.45 93.75 Z"
          />

            



        </svg>
        <div
          class="hidden flex justify-center"
          ref="enter"
        >
          <router-link to="/home">
            <button
              class="border-2 border-white hover:bg-gray-800 text-white font-bold py-2 px-4"
            >
              {{ $t('enter') }}
            </button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as vivus from 'vivus';
import LuisQuote from '../components/LuisQuote.vue'

export default {
  components: {
    LuisQuote,
  },
  mounted() {
   new vivus('logo', {type: 'sync', duration: 500}, this.onFinished());
  },
  methods: {
    onFinished() {
      window.setTimeout(() => { this.$refs['enter'].classList.remove("hidden"); } , 5000);
    },
  }
}
</script>
